import { FC } from "react";
import { Link } from "react-router-dom";
import { makeStyles, List, ListItem, ListItemText } from "@material-ui/core";

import edit from "../../../assets/edit.png";

import { CompanySection } from "./companySection";
import { CompanyViewTable } from "../companyViewTable";
import { ICompanyEditModalFunctions } from "../../../utils/companyEditModalFunctions";
import { IListData } from "../../company-edit/ListFieldEditModal";
import { SubsidiaryItem } from "../../company-edit/edit-subsidiaries/edit-subsidiaries-modal";
import { ListItemAvatar } from "@mui/material";
import { Avatar } from "../../avatar";

const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    marginTop: "30px",
  },
  sectionTitle: {
    color: "#333333",
    fontFamily: "Oswald",
    fontSize: "28px",
    letterSpacing: "0",
    lineHeight: "30px",
    marginBottom: "30px",
  },
  data: {
    display: "flex",
    justifyContent: "flex-start",
    flexWrap: "wrap",
    alignItems: "flex-end",
  },
  editButton: {
    background: "transparent",
    backgroundImage: `url(${edit})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    height: "20px",
    width: "20px",
    marginLeft: "10px",
    border: "none",
    cursor: "pointer",
    alignItems: "center",
    display: "inline-flex",
  },
  empty: {
    width: "100%",
    color: "#333",
    fontSize: "18px",
    lineHeight: "24px",
    fontFamily: "Montserrat",
    marginLeft: "5px",
  },
}));

interface Props {
  data: any;
  isEdit: boolean;
  getDataSource: (id: number) => { name: string; endpoint: string; id: number };
  editModalFunctions: ICompanyEditModalFunctions;
  getHighestPriorityDataList: (property_name: string) => any;
}

export const AlternativeNames: FC<Props> = ({
  data,
  isEdit,
  getDataSource,
  editModalFunctions,
  getHighestPriorityDataList,
}) => {
  const classes = useStyles();

  const parentHierarchy = data.parentHierarchy;

  type SubWithId = { id: number; name: string; image: string | null; sourceId: number };
  type ExternalSub = { name: string; sourceId: number };
  type Sub = ExternalSub | SubWithId;

  const subsidiariesFromEdit: Sub[] | null = getHighestPriorityDataList("subsidiaries")
    ? (getHighestPriorityDataList("subsidiaries").value as SubsidiaryItem[]).map((item: SubsidiaryItem) => {
        if (item.company) {
          return {
            ...item.company,
            sourceId: item.sourceId,
          };
        } else {
          return {
            name: item.externalCompany.name,
            sourceId: item.sourceId,
          };
        }
      })
    : null;

  const subsidiariesData: Sub[] = subsidiariesFromEdit || data?.subsidiaries || [];

  const subsidiaries = subsidiariesData.filter((sub) => "id" in sub && sub.id) as SubWithId[];
  const externalSubsidiaries = subsidiariesData.filter((sub) => !("id" in sub)) as ExternalSub[];

  const english_names_rows: any[] = [];
  const non_english_names_rows: any[] = [];

  const externalSubsidiariesRows: any[] = externalSubsidiaries.map((sub, index) => {
    return {
      rowTitle: sub.name,
    };
  });

  if (getHighestPriorityDataList("alternative_names")) {
    getHighestPriorityDataList("alternative_names")?.value?.forEach((alternative_name: IListData, index: number) => {
      english_names_rows.push({
        ...(index === 0 && {
          firstColumn: "",
          rowSpan: getHighestPriorityDataList("alternative_names")?.value?.length,
        }),
        rowTitle: alternative_name?.value,
      });
    });
  } else {
    data?.alternative_names?.value?.english_names?.forEach((alternative_name: any, index: number) => {
      english_names_rows.push({
        ...(index === 0 && {
          firstColumn: "",
          rowSpan: data?.alternative_names?.value?.english_names?.length,
        }),
        rowTitle: alternative_name?.name,
      });
    });
  }

  data?.alternative_names?.value?.non_english_names?.forEach((alternative_name: any, index: number) => {
    non_english_names_rows.push({
      ...(index === 0 && {
        firstColumn: "",
        rowSpan: data?.alternative_names?.value?.non_english_names?.length,
      }),
      rowTitle: alternative_name?.name,
    });
  });

  const renderContent = () => {
    return isEdit ||
      parentHierarchy ||
      english_names_rows.length > 0 ||
      non_english_names_rows.length > 0 ||
      subsidiaries.length > 0 ||
      externalSubsidiaries.length > 0 ? (
      <>
        <div>
          {subsidiaries.length || isEdit ? (
            <>
              <div className={classes.sectionTitle}>
                Subsidiaries in EIQ Database
                {isEdit && (
                  <button className={classes.editButton} onClick={editModalFunctions.openSubsidiariesEdit}></button>
                )}
              </div>
              <div className={classes.data}>
                {subsidiaries.map((subsidiary) => (
                  <Link to={`/companies/${subsidiary.id}`} style={{ textDecoration: "none" }} key={subsidiary.id}>
                    <CompanySection
                      data={subsidiary}
                      isEdit={isEdit}
                      sourceId={getDataSource(subsidiary.sourceId)}
                      getDataSource={getDataSource}
                    />
                  </Link>
                ))}
              </div>
            </>
          ) : null}
        </div>
        <div>
          {parentHierarchy ? (
            <>
              <div className={classes.sectionTitle}>Subsidiary Of</div>
              <div className={classes.data}>
                <List>
                  {parentHierarchy.reverse().map((parent: any, index: any) => (
                    <ListItem key={index}>
                      <ListItemAvatar>
                        <Avatar
                          name={parent.name}
                          logo={parent.image}
                          style={{
                            height: "32px",
                            width: "32px",
                            fontSize: "14px",
                            borderRadius: "50%",
                            objectFit: "contain",
                          }}
                        />
                      </ListItemAvatar>
                      {parent.id ? (
                        <Link to={`/companies/${parent.id}`} style={{ textDecoration: "none" }}>
                          <ListItemText
                            primary={parent.name}
                            style={{
                              color: "#333333",
                              fontFamily: "Oswald",
                              fontSize: "20px",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          />
                        </Link>
                      ) : (
                        <ListItemText
                          primary={parent.name}
                          style={{
                            color: "#333333",
                            fontFamily: "Oswald",
                            fontSize: "20px",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        />
                      )}
                    </ListItem>
                  ))}
                </List>
              </div>
            </>
          ) : null}
        </div>
        <div>
          {(english_names_rows.length > 0 || isEdit)  && (
            <div className="alternative-names-table-container">
              <CompanyViewTable
                title="Alternative Names"
                headers={[
                  { title: "Company Name", width: "60%" },
                  { title: "", width: "20%" },
                  { title: "", width: "20%" },
                ]}
                rows={english_names_rows}
                source="alternative_names"
                editFunction={editModalFunctions.openAlternativeNamesEdit}
                isEdit={isEdit}
                collapse
              />
            </div>
          )}
          {non_english_names_rows.length > 0 && (
            <div className="alternative-names-table-container">
              <CompanyViewTable
                title="Non-English Alternative Names"
                headers={[
                  { title: "Company Name", width: "60%" },
                  { title: "", width: "20%" },
                  { title: "", width: "20%" },
                ]}
                rows={non_english_names_rows}
                source="alternative_names"
                collapse
              />
            </div>
          )}
          {externalSubsidiariesRows.length > 0 && (
            <div className="alternative-names-table-container">
              <CompanyViewTable
                title="Subsidiaries"
                headers={[
                  { title: "Company Name", width: "60%" },
                  { title: "", width: "20%" },
                  { title: "Acquisition Date", width: "20%" },
                ]}
                rows={externalSubsidiariesRows}
                source="alternative_names"
                collapse
                isEdit={isEdit}
                editFunction={editModalFunctions.openSubsidiariesEdit}
              />
            </div>
          )}
        </div>
      </>
    ) : (
      <div className={classes.empty}>No Data.</div>
    );
  };

  return <div className={classes.container}>{renderContent()}</div>;
};
