import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(() => ({
  companyInfo: {
    minWidth: 200,
    marginTop: 50,
    marginRight: 60,
    display: "flex",
    flexDirection: "column",
  },
  companyName: {
    color: "#333333",
    fontFamily: "Oswald",
    fontSize: 39,
  },
  companyIndustry: {
    color: "#333333",
    fontFamily: "Montserrat",
    fontSize: 18,
    fontWeight: 500,
    marginTop: 10,
  },
  companyLink: {
    color: "#4D748D",
    fontFamily: "Montserrat",
    fontSize: 12,
    marginTop: 5,
  },
  companySubInfo: {
    marginTop: 40,
    display: "flex",
    flexDirection: "column",
  },
  subsidiaryOf: {
    display: "flex",
    flexDirection: "column",
    marginBottom: 10,
  },
  subsidiaryOfTitle: {
    color: "#333333",
    fontFamily: "Oswald",
    fontSize: 20,
  },
  companyInfoWrapper: {
    display: "flex",
    flexDirection: "column",
    gap: 20,
  },
  companyAddress: {
    marginTop: 60,
    display: "flex",
    flexDirection: "column",
  },
  addressTitle: {
    color: " #333333",
    fontFamily: "Oswald",
    fontSize: 16,
    marginBottom: 10,
  },
  address: {
    width: 266,
    color: "#333333",
    fontFamily: "Montserrat",
    fontSize: 16,
    marginBottom: 5,
  },
  contactParent: {
    display: "flex",
    flexDirection: "column",
  },
  phoneNumber: {
    color: "#333333",
    fontFamily: "Montserrat",
    fontSize: 16,
    maxHeight: 26,
    marginTop: 5,
  },
  contactTitle: {
    color: "#333333",
    fontFamily: "Oswald",
    fontSize: 16,
    fontWeight: 500,
  },
  button: {
    boxSizing: "border-box",
    padding: "12px 16px",
    height: 38,
    fontFamily: "Montserrat",
    fontSize: 12,
    border: "1px solid rgba(77,116,141,0.37)",
    borderRadius: 3,
    cursor: "pointer",
  },
  historyButton: {
    backgroundColor: "#FFFFFF",
    color: "#4D748D",
  },
  editButton: {
    backgroundColor: "#4D748D",
    color: "#FFFFFF",
  },
  primaryButton: {
    backgroundColor: "#4D748D",
    color: "#FFFFFF",
  },
  secondaryButton: {
    backgroundColor: "#FFFFFF",
    color: "#4D748D",
  },
  mapsParent: {
    height: "auto",
    marginTop: "-35px",
    width: "100%",
    display: "flex",
  },
  fadeImageContainer: {
    position: "relative",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    zIndex: 1, // Ensure it's behind the other elements
  },
  fadeImage: {
    display: "block",
    width: "100%",
    height: "100%",
    objectFit: "contain", // Maintain image aspect ratio and cover container
  },
  gradientOverlay: {
    content: "",
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "linear-gradient(to left, rgba(255, 255, 255, 0) 0%, rgba(246, 246, 246, 1) 100%)",
    pointerEvents: "none",
  },
  buttons: {
    position: "absolute",
    right: "5%",
    bottom: "30px",
    display: "flex",
    flexWrap: "nowrap",
    gap: "15px",
    zIndex: 3,
  },
  logo: {
    width: "100%",
    height: "100%",
    objectFit: "contain",
    objectPosition: "center",
  },
  hover: {
    "&:hover": {
      opacity: "0.7",
    },
  },
  message: {
    color: "#666666",
    fontFamily: "Montserrat",
    fontSize: "15px",
    fontWeight: "bold",
  },
  tooltip: {
    fontSize: "10px",
    lineHeight: "14px",
    fontFamily: "Montserrat",
    "& a": {
      color: "#4D748D",
    },
  },
}));
