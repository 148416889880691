import { type CompanyUpdateLogDetailStatus, TriggerEvent, UpdateLogFilters } from "./types";
import { DATA_SOURCES, LOG_STATUSES, PAGE_SIZE_OPTIONS, TRIGGER_EVENTS } from "./constants";

export const parseUrlFilters = (searchParams: URLSearchParams): UpdateLogFilters => {
  const updateLogSearchParams: UpdateLogFilters = {};

  const triggerEvents = searchParams.getAll("triggerEvent").filter((it) => TRIGGER_EVENTS.includes(it as any));
  updateLogSearchParams.triggerEvent = triggerEvents as TriggerEvent[];

  const statuses = searchParams.getAll("status").filter((it) => LOG_STATUSES.includes(it as any));
  updateLogSearchParams.status = statuses as CompanyUpdateLogDetailStatus[];

  const dataSources = searchParams
    .getAll("dataSourceId")
    .map((it) => parseInt(it, 10))
    .filter((it) => DATA_SOURCES.find((ds) => ds.id === it));
  updateLogSearchParams.dataSource = dataSources;

  const searchTerm = searchParams.get("companySearch");
  if (searchTerm && searchTerm.trim()) {
    updateLogSearchParams.searchTerm = searchTerm.trim();
  }

  const page = searchParams.get("page");
  if (page) {
    const pageParsed = parseInt(page, 10);
    if (pageParsed > 0) {
      updateLogSearchParams.page = pageParsed;
    }
  }

  const pageSize = searchParams.get("pageSize");
  if (pageSize) {
    const pageSizeParsed = parseInt(pageSize, 10);
    if (PAGE_SIZE_OPTIONS.includes(pageSizeParsed)) {
      updateLogSearchParams.pageSize = pageSizeParsed;
    }
  }

  return updateLogSearchParams;
};

export const filtersToSearchParams = (filters: UpdateLogFilters): URLSearchParams => {
  const searchParams = new URLSearchParams();

  if (filters.triggerEvent) {
    searchParams.delete("triggerEvent");
    for (const triggerEvent of filters.triggerEvent) {
      searchParams.append("triggerEvent", triggerEvent);
    }
  }

  if (filters.dataSource) {
    searchParams.delete("dataSourceId");
    for (const dataSourceId of filters.dataSource) {
      searchParams.append("dataSourceId", dataSourceId.toString());
    }
  }

  if (filters.status) {
    searchParams.delete("status");
    for (const status of filters.status) {
      searchParams.append("status", status);
    }
  }

  if (filters.searchTerm) {
    searchParams.set("companySearch", filters.searchTerm);
  }

  if (filters.page) {
    searchParams.set("page", filters.page.toString());
  }
  if (filters.pageSize) {
    searchParams.set("pageSize", filters.pageSize.toString());
  }

  return searchParams;
};

export const formatDate = (dateString: string): string => {
  return new Date(dateString).toLocaleString("en-US", {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  });
};
