import { Box, Container, Typography, Card, CardContent } from "@mui/material";

import { useUpdateLogs } from "./hooks";
import { UpdateLogTable } from "./components/UpdateLogTable";
import { UpdateLogFilter } from "./components/UpdateLogFilter";
import { UpdateLogPagination } from "./components/UpdateLogPagination";

export const CompanyUpdatesPage = () => {
  const {
    data,
    isLoading,
    error,
    filters,
    pagination,
    updateFilters,
    handlePageChange,
    handlePageSizeChange,
    isError,
  } = useUpdateLogs();

  if (isError) {
    return (
      <Container maxWidth="xl">
        <Box py={3}>
          <Card>
            <CardContent>
              <Typography color="error">Error loading company updates.</Typography>
              <Typography color="error">{error?.message ?? ""}</Typography>
            </CardContent>
          </Card>
        </Box>
      </Container>
    );
  }

  return (
    <Container maxWidth="xl">
      <Box py={3}>
        <Box mb={3}>
          <Typography variant="h4" gutterBottom>
            Company Updates
          </Typography>
          <Typography color="text.secondary">View and track all company data update activities</Typography>
        </Box>

        <UpdateLogFilter filters={filters} onFilterChange={updateFilters} />

        <UpdateLogTable logs={data} isLoading={isLoading} error={error} />

        {!isLoading && !isError && (
          <UpdateLogPagination
            page={pagination.page}
            pageSize={pagination.pageSize}
            count={pagination.count}
            onPageChange={handlePageChange}
            onPageSizeChange={handlePageSizeChange}
          />
        )}
      </Box>
    </Container>
  );
};
