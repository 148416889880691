import { makeStyles } from "@material-ui/core";
import { FC, useState, useRef, useEffect } from "react";
import { NavigationBar } from "../components/navigation";
import { SectionCard } from "../components/company-view/sectionCard";
import { RequestsTable } from "../components/user-requests/requestTable";
import { RequestsBanner } from "../components/user-requests/requestsBanner";
import { ModalComponent } from "../components/modalView";
import { apiConfig } from "../services/api/apiConfig";
import { Service } from "../services/api/service";
import { useAuth } from "../shared/hooks/auth-hook";
import buildQuery from "odata-query";
import { IRequest } from "../interfaces";
import { Select } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import moment from "moment";
const useStyles = makeStyles(() => ({
  banner: {
    width: "100%",
    height: "362px",
    backgroundColor: "#F6F6F6",
    marginTop: "-15px",
  },
  body: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    marginTop: "10px",
    paddingBottom: "50px",
  },
  leftNavigation: {
    width: "467px",
    marginTop: "40px",
  },
  message: {
    color: "#666666",
    fontFamily: "Montserrat",
    fontSize: "15px",
    fontWeight: "bold",
  },
}));

export const UserRequests: FC = () => {
  const classes = useStyles();
  const { token, user } = useAuth();
  const [newRequests, setNewRequests] = useState<IRequest[]>([]);
  const [pastRequests, setPastRequests] = useState<IRequest[]>([]);
  const [filteredPastRequests, setFiteredPastRequests] = useState<IRequest[]>([]);
  const [isUpdated, setIsUpdated] = useState<boolean>(false);
  const [updateMessage, setUpdateMessage] = useState<string>("");
  const [selectedRange, setSelectedRange] = useState<string>("2");

  const itemRefs = useRef<any>([null]);

  const dateFilter = ()  => {
    if (selectedRange === "1") return moment().subtract(1, 'months');
    if (selectedRange === "2") return moment().subtract(3, 'months');
    return moment().subtract(6, 'months');
  }

  const sections = [
    { name: "REQUESTS", id: "requests" },
    { name: "HELP / TUTORIALS", id: "help", help: true },
  ]; // Nav sections

  const scrollToSection = (index: string) => {
    window.scrollTo({
      behavior: "smooth",
      top: itemRefs.current[index].offsetTop - 400,
    });
  };

  const handleRequestUpdated = (updated: boolean, data: string) => {
    setIsUpdated(updated);
    setUpdateMessage(data);
  };

  useEffect(() => {
    if (!pastRequests) return;

    if (selectedRange === "4") {
      setFiteredPastRequests(pastRequests);
    } else {
      const startDate = dateFilter();
      const filtered = pastRequests.filter((request) => moment(request.newest_request_creation_date) > startDate);
      setFiteredPastRequests(filtered);
    }
  }, [pastRequests, selectedRange]);

  useEffect(() => {
    const params = {
      orderBy: "created_at asc",
      groupBy: "created_by",
    };
    Service.get(apiConfig.companyCreation, token as string, {
      ...params,
      filter: buildQuery({ filter: [{ status_id: 3 }] }).substring(9),
    })
      .then((response: any) => response.data.data)
      .then((data: any) => setNewRequests(data));

    Service.get(apiConfig.companyCreation, token as string, {
      ...params,
      filter: buildQuery({ filter: [{ status_id: { ne: 3 } }] }).substring(9),
    })
      .then((response: any) => response.data.data)
      .then((data: any) => setPastRequests(data));
  }, []);

  return (
    <div>
      <div className={classes.banner}>
        <RequestsBanner
          data={{}}
          fullName={user?.displayName}
          role={user?.role}
          profilePictureUrl={user?.profilePictureUrl}
        />
      </div>
      <div className={classes.body}>
        <div className={classes.leftNavigation}>
          <NavigationBar sectionNames={sections} scrollToSection={scrollToSection} />
        </div>
        <div style={{ marginTop: "40px" }}>
          <div id="requests">
            <SectionCard title="New Company Requests">
              <RequestsTable data={newRequests} type="new" isUpdated={handleRequestUpdated} />
            </SectionCard>
            <SectionCard title="Past requests">
              <div style={{paddingBottom: 20}}>
              <span style={{ fontFamily: "Montserrat", fontSize: 12, paddingRight: 10}}>Show users with last requests from</span>
              <Select
                sx={{fontFamily: "Montserrat", fontSize: 12}}
                value={selectedRange}
                defaultValue={"3"}
                onChange={(event) => setSelectedRange(event.target.value as string)}>
                <MenuItem sx={{fontFamily: "Montserrat", fontSize: 12}}  value="1">Past month</MenuItem>
                  <MenuItem sx={{fontFamily: "Montserrat", fontSize: 12}} value="2">Past 3 months</MenuItem>
                  <MenuItem sx={{fontFamily: "Montserrat", fontSize: 12}} value="3">Past 6 months</MenuItem>
                  <MenuItem sx={{fontFamily: "Montserrat", fontSize: 12}} value="4">All time</MenuItem>
              </Select>
              </div>
              <RequestsTable data={filteredPastRequests} type="past" isUpdated={handleRequestUpdated} />
            </SectionCard>
          </div>
        </div>
      </div>
      <ModalComponent title="" open={isUpdated} setOpen={(data: boolean) => setIsUpdated(data)}>
        <div>
          <span className={classes.message}>{updateMessage}</span>
        </div>
      </ModalComponent>
    </div>
  );
};
