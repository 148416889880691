import { Alert, AlertColor, Snackbar } from "@mui/material";

interface AlertSnackbarProps {
  open: boolean;
  message: string;
  severity: AlertColor;
  onClose: () => void;
}

export const AlertSnackbar = ({ open, message, severity, onClose }: AlertSnackbarProps) => (
  <Snackbar open={open} autoHideDuration={6000} onClose={onClose}>
    <Alert onClose={onClose} severity={severity} variant="filled">
      {message}
    </Alert>
  </Snackbar>
);