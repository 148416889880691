import React, { useState } from "react";
import { IconButton, ListItemIcon, ListItemText, Menu, MenuItem } from "@mui/material";
import {
  Archive as ArchiveIcon,
  Download as DownloadIcon,
  MoreVert as MoreVertIcon,
  Refresh as RefreshIcon,
} from "@mui/icons-material";

import { useAuth } from "../../../shared/hooks/auth-hook";
import type { CompanyUpdateLog } from "../types";
import { archiveCompany, retryCompanyUpdate } from "../api";
import { ConfirmationDialog } from "./ConfirmationDialog";
import { AlertSnackbar } from "./AlertSnackbar";

type ConfirmationAction = "archive" | "retry" | null;

interface UpdateLogActionsMenuProps {
  log: CompanyUpdateLog;
  onArchiveSuccess?: () => void;
  onRetrySuccess?: () => void;
}

export const UpdateLogActionsMenu = ({ log, onArchiveSuccess, onRetrySuccess }: UpdateLogActionsMenuProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);
  const [confirmAction, setConfirmAction] = useState<ConfirmationAction>(null);
  const [alert, setAlert] = useState<{
    open: boolean;
    message: string;
    severity: "success" | "error";
  }>({ open: false, message: "", severity: "success" });
  const { token } = useAuth();

  const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const onArchiveCompanyMenuOptionClick = () => {
    setAnchorEl(null);
    setConfirmAction("archive");
  };

  const onRetryUpdateMenuOptionClick = () => {
    setAnchorEl(null);
    setConfirmAction("retry");
  };

  const handleArchiveCompany = async () => {
    setConfirmAction(null);

    try {
      await archiveCompany(token!, log.company.id);

      setAlert({
        open: true,
        message: "Company archived successfully",
        severity: "success",
      });
      onArchiveSuccess?.();
    } catch (err) {
      setAlert({
        open: true,
        message: err instanceof Error ? err.message : "Failed to archive company",
        severity: "error",
      });
    }
  };

  const handleRetryUpdate = async () => {
    setConfirmAction(null);

    try {
      setAlert({
        open: true,
        message: "Update scheduled successfully",
        severity: "success",
      });

      // Note: This will resolve once the update is completely finished. so run alert before it.
      await retryCompanyUpdate(token!, log);

      setAlert({
        open: true,
        message: "Company update completed",
        severity: "success",
      });
      onRetrySuccess?.();
    } catch (err) {
      setAlert({
        open: true,
        message: err instanceof Error ? err.message : "Failed to schedule update",
        severity: "error",
      });
    }
  };

  const getConfirmationProps = () => {
    switch (confirmAction) {
      case "archive":
        return {
          title: "Archive Company",
          description: (
            <span>
              Are you sure you want to archive <b>{log.company.name}</b> This action will hide the company from active
              views and can be reversed later.
            </span>
          ),
          confirmLabel: "Archive",
          onConfirm: handleArchiveCompany,
        };
      case "retry":
        return {
          title: "Retry Update",
          description: (
            <span>
              Are you sure you want to schedule a new update for <b>${log.company.name}</b>?
            </span>
          ),
          confirmLabel: "Retry Update",
          onConfirm: handleRetryUpdate,
        };
      default:
        return null;
    }
  };

  const handleDownloadJson = () => {
    const jsonString = JSON.stringify(log, null, 2);
    const blob = new Blob([jsonString], { type: "application/json" });

    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = `company-update-log-${log.id}.json`;

    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    URL.revokeObjectURL(url);

    setAnchorEl(null);
  };

  return (
    <>
      <IconButton size="small" onClick={handleMenuOpen}>
        <MoreVertIcon fontSize="small" />
      </IconButton>
      <Menu anchorEl={anchorEl} open={open} onClose={handleMenuClose}>
        <MenuItem onClick={handleDownloadJson}>
          <ListItemIcon>
            <DownloadIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Download JSON</ListItemText>
        </MenuItem>
        <MenuItem onClick={onArchiveCompanyMenuOptionClick}>
          <ListItemIcon>
            <ArchiveIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Archive Company</ListItemText>
        </MenuItem>
        <MenuItem onClick={onRetryUpdateMenuOptionClick}>
          <ListItemIcon>
            <RefreshIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Retry Update</ListItemText>
        </MenuItem>
      </Menu>
      {confirmAction && (
        <ConfirmationDialog
          open={Boolean(confirmAction)}
          {...getConfirmationProps()!}
          onCancel={() => setConfirmAction(null)}
        />
      )}
      <AlertSnackbar
        open={alert.open}
        message={alert.message}
        severity={alert.severity}
        onClose={() => setAlert((prev) => ({ ...prev, open: false }))}
      />
    </>
  );
};
