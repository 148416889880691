import { FC, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";

import logo from "../../assets/Ensemble-logo.png";
import { ModalComponent } from "../../components/modalView";
import { ManageUsersMenu } from "../../components/manageUsersMenu";
import { CompanyCreate } from "../../components/company-create/companyCreate";
import { useAuth } from "../hooks/auth-hook";
import { ExportCompanies } from "../../components/export-companies/exportCompanies";

const useStyles = makeStyles(() => ({
  createButton: {
    color: "#4D748D",
    fontFamily: "Montserrat",
    fontSize: "12px",
    textAlign: "center",
    height: "38px",
    width: "136px",
    border: "1px solid #4D748D",
    borderRadius: "3px",
    backgroundColor: "#FFFFFF",
    float: "right",
    marginRight: "8px",
    marginLeft: "44px",
    cursor: "pointer",
  },
  exportButton: {
    color: "#4D748D",
    fontFamily: "Montserrat",
    fontSize: "12px",
    textAlign: "center",
    height: "38px",
    width: "136px",
    border: "1px solid #4D748D",
    borderRadius: "3px",
    backgroundColor: "#FFFFFF",
    float: "right",
    marginRight: "50px",
    cursor: "pointer",
  },
  message: {
    color: "#666666",
    fontFamily: "Montserrat",
    fontSize: "15px",
    fontWeight: "bold",
  },
}));

export const MainNavigation: FC = () => {
  const classes = useStyles();

  const { isLoggedIn, hasPermission } = useAuth();

  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [exportModalOpen, setExportModalOpen] = useState<boolean>(false);
  const [messageOpen, setMessageOpen] = useState<boolean>(false);
  const [createMessage, setCreateMessage] = useState<string>("");

  const handleCreateOpen = (open: boolean, message: string) => {
    setModalOpen(open);
    if (message) {
      setCreateMessage(message);
      setMessageOpen(true);
    }
  };

  return (
    <header className="eiqdb-header position-relative-on-print">
      <div className="eiqdb-header-row">
        <div className="eiqdb-product-name-wrapper">
          <Link className="eiqdb-logo-link" to="/">
            <img className="eiqdb-logo" src={logo} alt="Home" />
          </Link>
          <span className="eiqdb-product-name-devider"></span>
          <span className="eiqdb-product-name">Company Database</span>
        </div>

        {isLoggedIn && (
          <>
            <div className="eiqdb-nav-section no-print">
              <ManageUsersMenu />
              {(hasPermission("CompanyCreate.ReadWrite.asUser") || hasPermission("CompanyCreate.ReadWrite.All")) && (
                <button className={classes.createButton} onClick={() => setModalOpen(true)}>
                  Create new entry
                </button>
              )}
              {(hasPermission("Company.Export.All")) && (
                <button className={classes.exportButton} onClick={() => setExportModalOpen(true)}>
                  Export companies
                </button>
              )}
            </div>
            <ModalComponent title="Create New Entry" open={modalOpen} setOpen={(data: boolean) => setModalOpen(data)}>
              <CompanyCreate setOpen={handleCreateOpen} />
            </ModalComponent>
            <ModalComponent title="Export companies" open={exportModalOpen} setOpen={(data: boolean) => setExportModalOpen(data)}>
              <ExportCompanies setOpen={setExportModalOpen} />
            </ModalComponent>
            <ModalComponent title="" open={messageOpen} setOpen={(data: boolean) => setMessageOpen(data)}>
              <div>
                <span className={classes.message}>{createMessage}</span>
              </div>
            </ModalComponent>
          </>
        )}
      </div>
    </header>
  );
};
