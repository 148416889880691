import { Avatar } from "@mui/material";
import { Business as BusinessIcon, Person as PersonIcon, SvgIconComponent } from "@mui/icons-material";

export type EntityType = "user" | "company";

interface EntityAvatarProps {
  image?: string | null;
  name: string;
  size?: number;
  type: EntityType;
}

function resolveEntityAvatarIcon(type: EntityType): SvgIconComponent {
  if (type === "company") {
    return BusinessIcon;
  }

  return PersonIcon;
}

export const EntityAvatar = ({ image, name, size = 24, type }: EntityAvatarProps) => {
  const FallbackIcon = resolveEntityAvatarIcon(type);

  return (
    <Avatar
      src={image ?? undefined}
      alt={name}
      sx={{
        width: size,
        height: size,
        bgcolor: image ? "transparent" : "grey.100",
      }}
    >
      {!image && <FallbackIcon sx={{ width: size, height: size, color: "grey.500" }} />}
    </Avatar>
  );
};
