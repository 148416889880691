import { makeStyles } from "@material-ui/core";
import { FormEvent, FC, useState } from "react";
import { CheckboxSelect } from "../checkbox";
import { Service } from "../../services/api/service";
import { apiConfig } from "../../services/api/apiConfig";
import  {useAuth} from "../../shared/hooks/auth-hook";
import { ExcelExport } from "../../utils/exportToExcel";

const useStyles = makeStyles(() => ({
  title: {
    color: "#333333",
    fontFamily: "Montserrat",
    fontSize: "12px",
    fontWeight: "bold",
    marginBottom: "4px",
  },
  formGroup: {
    display: "flex",
    flexDirection: "column",
    "& .MuiCircularProgress-root": {
      width: "20px !important",
      height: "20px !important",
      color: "#4D748D",
      marginRight: "5px",
      marginTop: "5px",
    },
  },
  input: {
    border: "1px solid #E1E1E1",
    padding: 10,
    width: "100%",
    color: "#666666",
    fontWeight: 400,
    fontFamily: "Montserrat",
    "&::placeholder": {
      color: "#666666",
      fontWeight: 400,
      fontFamily: "Montserrat",
      fontSize: 12,
    },
    "&:focus": {
      outline: "none",
    },
  },
  button: {
    boxSizing: "border-box",
    height: "38px",
    width: "110px",
    border: "1px solid #4D748D",
    borderRadius: "3px",
    marginRight: "15px",
  },
  nameError: {
    color: "#c40014",
    fontFamily: "Montserrat",
    fontSize: "11px",
    fontWeight: "bold",
  },
}));

interface Props {
  setOpen: (open: boolean, message: string) => void;
}

type QueryParameters = {
  name: boolean;
  domain: boolean;
  type: boolean;
  country: boolean;
  secDomains: boolean;
  subsidiaries: boolean;
  altNames: boolean;
};

type ParamsName = keyof QueryParameters;

const DEFAULT_QUERY_PARAMS = {
  name: true,
  domain: true,
  type: false,
  country: false,
  secDomains: false,
  subsidiaries: false,
  altNames: false,
};

export const ExportCompanies: FC<Props> = ({ setOpen }) => {
  const classes = useStyles();
  const [params, setParams] = useState<QueryParameters>(DEFAULT_QUERY_PARAMS);
  const {token} = useAuth();
  const excelExport = new ExcelExport();
  const handleCancel = () => {
    setParams(DEFAULT_QUERY_PARAMS);
    setOpen(false, "");
  };

  const optionsArray = Object.keys(DEFAULT_QUERY_PARAMS) as ParamsName[];

  const toggleParams = (checked: boolean, paramName: ParamsName) => {
      setParams({
        ...params,
        [paramName]: checked,
      })
  };


  const checkboxLabel = (name: ParamsName) => {
    if (name === "type") return "Ownership type";
    if (name === "secDomains") return "Secondary domains";
    if (name === "altNames") return "Alternative names";

    return name.charAt(0).toUpperCase() + name.slice(1);
  };

  const buildQueryString = () => {
    const queryParts: string[] = [];

    for (const key in params) {
      const keyTyped = key as ParamsName;
      if (params[keyTyped]) {
        queryParts.push(`${encodeURIComponent(key)}=${encodeURIComponent(params[keyTyped])}`);
      }
    }

    return queryParts.join('&');
  }

  const createReport = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    Service.get(`${apiConfig.companiesExport}?${buildQueryString()}`, token as string)
      .then((response) => {
        if (response.status === 200) {
          excelExport.export("companies", response?.data);
        }
      })
      .catch((err) => {
       console.log(err);
      });
    setOpen(false, "");
  };

  return (
    <form onSubmit={createReport}>
      <div className={classes.formGroup} style={{ marginBottom: "10px" }}>
        {optionsArray.map((option, index) => {
          return (
            <div style={{ marginRight: "auto" }} key={index}>
              <CheckboxSelect
                checkboxLabel={checkboxLabel(option)}
                checked={params[option]}
                setChecked={(checked: boolean) => toggleParams(checked, option)}
                labelStyling={{
                  marginLeft: -3,
                  color: "#4d748d",
                  fontFamily: "Montserrat, sans-serif",
                  fontSize: 12,
                }}
              />
            </div>
          )
        })}

      </div>
      <div style={{ display: "flex" }}>
        <button
          className={classes.button}
          type="button"
          style={{ backgroundColor: "#FFFFFF", color: "#4D748D", cursor: "pointer" }}
          onClick={handleCancel}
        >
          Cancel
        </button>
        <button
          className={classes.button}
          type="submit"
          style={{
            backgroundColor: "#4D748D",
            color: "#FFFFFF",
            cursor: "pointer",
            opacity: "100%",
          }}
        >
          Export
        </button>
      </div>
      <div className={classes.formGroup} style={{ marginTop: "15px" }}>
      </div>
    </form>
  );
};
