import { FC} from "react";

interface Props {
  address: string;
  className?: string;
}

export const Maps: FC<Props> = ({ address , className}) => {
  return (
      <img
        alt="map"
        className={className}
        style={className ? undefined : {
          height: "100%",
          width: "auto",
          maxWidth: 850,
          overflowX: "hidden",
        }}
        src={`https://maps.googleapis.com/maps/api/staticmap?center=${address}&zoom=15&size=600x300&scale=4&maptype=roadmap&markers=size:small%7Ccolor:0x4D748D%7C${address}
        &key=${process.env.REACT_APP_MAPS_API_KEY}&map_id=dcd404666210ad02`}
      />

  );
};
