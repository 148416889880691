import type { CompanyUpdateLogDetailStatus, DataSource, TriggerEvent } from "./types";

export const TRIGGER_EVENTS: readonly TriggerEvent[] = [
  "User Request",
  "Scheduled Update",
  "Data Source Mapping Overwrite",
  "Company Creation",
] as const;

export const LOG_STATUSES: readonly CompanyUpdateLogDetailStatus[] = ["success", "warning", "error"] as const;

export const DATA_SOURCES: readonly DataSource[] = [
  {
    id: 1,
    name: "Diffbot",
  },
  {
    id: 8,
    name: "SEC",
  },
] as const;

export const DEFAULT_PAGE_SIZE = 25;
export const PAGE_SIZE_OPTIONS = [10, 25, 50, 100];
