import { Box, Pagination, Select, MenuItem, FormControl, InputLabel, Stack, Typography } from "@mui/material";
import { PAGE_SIZE_OPTIONS } from "../constants";

interface UpdateLogPaginationProps {
  page: number;
  pageSize: number;
  count: number;
  onPageChange: (page: number) => void;
  onPageSizeChange: (pageSize: number) => void;
}

export const UpdateLogPagination = ({
  page,
  pageSize,
  count,
  onPageChange,
  onPageSizeChange,
}: UpdateLogPaginationProps) => {
  const pageCount = Math.ceil(count / pageSize);
  const startItem = count === 0 ? 0 : (page - 1) * pageSize + 1;
  const endItem = Math.min(page * pageSize, count);

  return (
    <Box py={2}>
      <Stack
        direction={{ xs: "column", sm: "row" }}
        spacing={2}
        alignItems={{ xs: "stretch", sm: "center" }}
        justifyContent="space-between"
      >
        <Stack direction="row" spacing={2} alignItems="center">
          <FormControl size="small" sx={{ minWidth: 120 }}>
            <InputLabel>Page Size</InputLabel>
            <Select value={pageSize} label="Page Size" onChange={(e) => onPageSizeChange(Number(e.target.value))}>
              {PAGE_SIZE_OPTIONS.map((size) => (
                <MenuItem key={size} value={size}>
                  {size} per page
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Typography variant="body2" color="text.secondary">
            {count === 0 ? "No results" : `${startItem}-${endItem} of ${count}`}
          </Typography>
        </Stack>
        <Pagination
          page={page}
          count={pageCount}
          onChange={(_, newPage) => onPageChange(newPage)}
          color="primary"
          shape="rounded"
          showFirstButton
          showLastButton
          disabled={count === 0}
        />
      </Stack>
    </Box>
  );
};
