import type { ApiResponse, CompanyUpdateLog, UpdateLogFilters } from "./types";
import { filtersToSearchParams } from "./utils";
import { apiConfig } from "../../services/api/apiConfig";

export const fetchUpdateLogs = async (token: string, params?: UpdateLogFilters): Promise<ApiResponse> => {
  const urlSearchParams = filtersToSearchParams(params || {});
  const queryString = urlSearchParams.toString();
  let url = `${process.env.REACT_APP_DATA_MANAGER_URL}/api/v1/admin/company-updates`;
  if (queryString) {
    url += `?${queryString}`;
  }

  const response = await fetch(url, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  if (!response.ok) {
    throw new Error("Failed to fetch update logs");
  }
  return response.json();
};

export const archiveCompany = async (token: string, companyId: number) => {
  const response = await fetch(`${apiConfig.admin}/company-profiles/${companyId}/status`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      status: "ARCHIVED",
    }),
  });

  if (!response.ok) {
    throw new Error("Failed to archive company");
  }

  return;
};

export const retryCompanyUpdate = async (token: string, log: CompanyUpdateLog) => {
  const response = await fetch(`${apiConfig.companyRefreshDataSources}/${log.company.id}/refresh-data-sources`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    // body: JSON.stringify({
    //   logId: log.id,
    //   taskId: log.taskId,
    // }),
  });

  if (!response.ok) {
    throw new Error("Failed to schedule update");
  }

  return;
};
