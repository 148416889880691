import { makeStyles } from "@material-ui/core/styles";
import backgroundImage from "../../assets/resultsBackground.png";

export const useStyles = makeStyles(() => ({
  container: {
    position: "relative",
    paddingTop: "100px",
    display: "flex",
    "&::before": {
      content: "''",
      width: "100%",
      height: 130,
      position: "absolute",
      top: 0,
      left: -5,
      zIndex: -1,
      backgroundImage: `url(${backgroundImage})`,
      backgroundSize: "cover",
    },
  },
  filters: {
    background: "white",
    width: 350,
    boxShadow: "0 17px 40px 0 rgba(0,0,0,0.06), 0 2px 20px 0 rgba(0,0,0,0.1)",
    marginTop: 30,
  },
  results: {
    background: "white",
    marginTop: 30,
    marginLeft: 30,
    padding: "50px 70px 50px 70px",
    width: "100%",
  },
  filtersText: {
    color: "#666666",
    fontFamily: "Oswald",
    fontSize: 22,
    float: "left",
    clear: "left",
    marginBottom: 10,
  },
  resultsText: {
    color: "#333333",
    fontFamily: "Oswald",
    fontSize: 39,
    fontWeight: 500,
    flex: 1,
  },
  resultsCountText: {
    color: "#666666",
    fontFamily: "Oswald",
    fontSize: 16,
    fontWeight: 500,
    textAlign: "center",
    marginLeft: 10,
  },
  countText: {
    color: "#666666",
    fontFamily: "Montserrat",
    fontSize: 14,
    fontWeight: 700,
    float: "left",
    clear: "left",
    marginBottom: 10,
  },
  totalCountText: {
    color: "#666666",
    fontFamily: "Montserrat",
    fontWeight: 400,
    fontSize: 14,
  },
  clearFiltersDiv: {
    float: "left",
    clear: "left",
    "& .MuiButton-root": {
      padding: 0,
    },
    marginBottom: 20,
  },
  searchInputDiv: {
    float: "left",
    clear: "left",
    width: 290,
    height: 38,
    marginBottom: 30,
  },
  rectangle: {
    height: 1,
    width: "100%",
    backgroundColor: "#E1E1E1",
    clear: "left",
  },
  buttons: {
    height: 80,
    display: "flex",
    justifyContent: "end",
    padding: 20,
  },
  exportButton: {
    background: "transparent",
    border: "none",
    cursor: "pointer",
    alignItems: "center",
    display: "inline-flex",
    color: "#333333",
    fontFamily: "Montserrat",
    fontSize: 14,
    fontWeight: 500,
  },
  selectedFilter: {
    borderRadius: 4,
    backgroundColor: "#75AED2",
    color: "#FFFFFF",
    fontFamily: "Open Sans",
    fontSize: 10,
    fontWeight: "bold",
    padding: "5px 10px",
    display: "inline-block",
    marginRight: 5,
  },
  refineText: {
    color: "#666666",
    fontFamily: "Montserrat",
    fontSize: 14,
    fontWeight: 700,
  },
  letterShape: {
    boxSizing: "border-box",
    height: 25,
    width: 25,
    border: "1px solid #4D748D",
    borderRadius: 16.5,
    textAlign: "center",
    color: "#4D748D",
    fontFamily: "Montserrat",
    fontSize: 12,
    marginTop: 15,
    marginRight: 8,
    cursor: "pointer",
  },
  selectedLetter: {
    backgroundColor: "#4D748D",
    color: "#FFFFFF",
  },
  companyLetter: {
    color: "#333333",
    fontFamily: "Oswald",
    fontSize: 32,
    fontWeight: 500,
    marginLeft: 2,
  },
  line: {
    boxSizing: "border-box",
    height: 2,
    width: 21,
    border: "2px solid #4D748D",
    backgroundColor: "#4D748D",
    marginBottom: 30,
  },
  divider: {
    boxSizing: "border-box",
    height: 1,
    border: "1px solid #ECECEC",
  },
  leftArrow: {
    border: "solid white",
    borderWidth: "0 3px 3px 0",
    display: "inline-block",
    padding: 3,
    transform: "rotate(135deg)",
    marginRight: 10,
  },
  backButton: {
    background: "transparent",
    border: "none",
    cursor: "pointer",
    alignItems: "center",
    display: "inline-flex",
    color: "#FFFFFF",
    fontFamily: "Montserrat",
    fontSize: 12,
    fontWeight: 600,
    height: 30,
  },
  backButtonDiv: {
    minWidth: 0,
    position: "absolute",
    top: "15px",
    left: "20px",
  },
  paginationContainer: {
    marginTop: 60,
  },
  letterDisabled: {
    cursor: "auto",
    background: "#CCCCCC",
    color: "#FFFFFF",
  },
  dropdownButton: {
    color: "#333333",
    fontFamily: "Montserrat",
    fontSize: "12px",
    float: "left",
    clear: "left",
    height: "40px",
    display: "inline-flex",
    alignItems: "center",
    width: "100%",
    border: "none",
    backgroundColor: "#FFFFFF",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "rgb(77, 116, 141, 0.1)",
    },
  },
}));
