import { useState } from "react";
import { Chip, Collapse, IconButton, Stack, Typography } from "@mui/material";
import { KeyboardArrowDown as ExpandMoreIcon, KeyboardArrowUp as ExpandLessIcon } from "@mui/icons-material";

import { CompanyUpdateLog } from "../types";
import Tooltip from "@mui/material/Tooltip";

interface UpdateLogStatusProps {
  log: CompanyUpdateLog;
}

export const UpdateLogStatus = ({ log }: UpdateLogStatusProps) => {
  const [expanded, setExpanded] = useState(false);
  const hasIssues = log.issues.length > 0;

  return (
    <Stack spacing={1}>
      <Stack direction="row" spacing={1} alignItems="center">
        <Chip
          label={<span style={{ textTransform: "capitalize" }}>{log.status}</span>}
          color={log.status}
          size="small"
        />
        {hasIssues && (
          <Tooltip title={expanded ? "Hide Issues" : "Show Issues"} placement="top-start">
            <IconButton size="small" onClick={() => setExpanded(!expanded)} sx={{ p: 0.5 }}>
              {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
          </Tooltip>
        )}
      </Stack>

      {hasIssues && (
        <Collapse in={expanded}>
          <Stack
            spacing={1}
            sx={{
              pl: 2,
              borderLeft: (theme) => `2px solid ${theme.palette.error.light}`,
              my: 1,
            }}
          >
            {log.issues.map((issue, index) => (
              <Stack key={index} spacing={0.5}>
                <Typography variant="caption" color="error" sx={{ fontWeight: "medium" }}>
                  {issue.field}
                </Typography>
                <Typography variant="caption" color="text.secondary" sx={{ fontSize: "0.75rem" }}>
                  {issue.message}
                </Typography>
              </Stack>
            ))}
          </Stack>
        </Collapse>
      )}
    </Stack>
  );
};
