import {
  Box,
  Checkbox,
  Chip,
  FormControl,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Tooltip,
} from "@mui/material";
import { Clear as ClearIcon, FilterList as FilterIcon } from "@mui/icons-material";

import type { CompanyUpdateLogDetailStatus, TriggerEvent, UpdateLogFilters } from "../types";
import { DATA_SOURCES, LOG_STATUSES, TRIGGER_EVENTS } from "../constants";

interface UpdateLogFiltersProps {
  filters: UpdateLogFilters;
  onFilterChange: (filtersChange: Partial<UpdateLogFilters>) => void;
}

export const UpdateLogFilter = ({ filters, onFilterChange }: UpdateLogFiltersProps) => {
  const handleTriggerEventsChange = (event: SelectChangeEvent<TriggerEvent[]>) => {
    const value = event.target.value;
    onFilterChange({ triggerEvent: typeof value === "string" ? [] : value });
  };

  const handleDataSourcesChange = (event: SelectChangeEvent<number[]>) => {
    const value = event.target.value;
    onFilterChange({ dataSource: typeof value === "string" ? [] : value });
  };

  const handleStatusChange = (event: SelectChangeEvent<CompanyUpdateLogDetailStatus[]>) => {
    const value = event.target.value;
    onFilterChange({ status: typeof value === "string" ? [] : value });
  };

  const handleSearchChange = (value: string) => {
    onFilterChange({ searchTerm: value });
  };

  const handleClearFilters = () => {
    onFilterChange({
      triggerEvent: [],
      dataSource: [],
      status: [],
      searchTerm: "",
    });
  };

  const hasActiveFilters = Boolean(
    (filters.triggerEvent && filters.triggerEvent.length > 0) ||
      (filters.dataSource && filters.dataSource.length > 0) ||
      (filters.status && filters.status.length > 0) ||
      filters.searchTerm
  );

  return (
    <Paper sx={{ p: 2, mb: 2 }}>
      <Stack direction="row" spacing={2} alignItems="center">
        <FilterIcon color="action" />

        {/* Search */}
        <TextField
          size="small"
          placeholder="Search by company name..."
          value={filters.searchTerm}
          onChange={(e) => handleSearchChange(e.target.value.trim())}
          sx={{ width: 250 }}
        />

        {/* Trigger Events filter */}
        <FormControl sx={{ width: 300 }} size="small">
          <InputLabel>Trigger Event</InputLabel>
          <Select
            multiple
            value={filters.triggerEvent ?? []}
            onChange={handleTriggerEventsChange}
            input={<OutlinedInput label="Trigger Event" />}
            renderValue={(selected) => (
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                {selected.map((value) => (
                  <Chip key={value} label={value} size="small" />
                ))}
              </Box>
            )}
          >
            {TRIGGER_EVENTS.map((event) => (
              <MenuItem dense key={event} value={event}>
                <Checkbox checked={(filters.triggerEvent ?? []).indexOf(event) > -1} />
                <ListItemText primary={event} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {/* Data Source filter */}
        <FormControl sx={{ width: 200 }} size="small">
          <InputLabel>Data Source</InputLabel>
          <Select
            multiple
            value={filters.dataSource ?? []}
            onChange={handleDataSourcesChange}
            input={<OutlinedInput label="Data Source" />}
            renderValue={(selected) => (
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                {selected.map((value) => (
                  <Chip key={value} label={DATA_SOURCES.find((it) => it.id === value)?.name} size="small" />
                ))}
              </Box>
            )}
          >
            {DATA_SOURCES.map((option) => (
              <MenuItem dense key={option.id} value={option.id}>
                <Checkbox checked={(filters.dataSource ?? []).includes(option.id)} />
                <ListItemText primary={option.name} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {/* Status */}
        <FormControl sx={{ width: 200 }} size="small">
          <InputLabel>Status</InputLabel>
          <Select
            multiple
            value={filters.status ?? []}
            onChange={handleStatusChange}
            input={<OutlinedInput label="Status" />}
            renderValue={(selected) => (
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                {selected.map((value) => (
                  <Chip key={value} label={value} size="small" />
                ))}
              </Box>
            )}
          >
            {LOG_STATUSES.map((event) => (
              <MenuItem dense key={event} value={event}>
                <Checkbox checked={(filters.status ?? []).indexOf(event) > -1} />
                <ListItemText primary={event} sx={{ textTransform: "capitalize" }} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {/* Clear filters button */}
        {hasActiveFilters && (
          <Tooltip title="Clear filters">
            <IconButton size="small" onClick={handleClearFilters} sx={{ ml: "auto" }}>
              <ClearIcon />
            </IconButton>
          </Tooltip>
        )}
      </Stack>
    </Paper>
  );
};
