import { Skeleton, TableCell, TableRow } from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

interface UpdateLogTableSkeletonProps {
  rowCount?: number;
}

export const UpdateLogTableSkeleton = ({ rowCount = 5 }: UpdateLogTableSkeletonProps) => {
  return (
    <>
      {Array.from({ length: rowCount }).map((_, index) => (
        <StyledTableRow key={index}>
          {/* Company cell with avatar + name */}
          <TableCell>
            <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
              <Skeleton variant="circular" width={24} height={24} />
              <Skeleton variant="text" width={150} />
            </div>
          </TableCell>

          {/* Trigger Event */}
          <TableCell>
            <Skeleton variant="text" width={100} />
          </TableCell>

          {/* Data Source */}
          <TableCell>
            <Skeleton variant="text" width={120} />
          </TableCell>

          {/* Created At */}
          <TableCell>
            <Skeleton variant="text" width={100} />
          </TableCell>

          {/* Created By with avatar + name */}
          <TableCell>
            <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
              <Skeleton variant="circular" width={24} height={24} />
              <Skeleton variant="text" width={120} />
            </div>
          </TableCell>

          {/* Status chip */}
          <TableCell>
            <Skeleton variant="rectangular" width={80} height={24} sx={{ borderRadius: 1 }} />
          </TableCell>

          {/* Actions */}
          <TableCell>
            <Skeleton variant="circular" width={32} height={32} />
          </TableCell>
        </StyledTableRow>
      ))}
    </>
  );
};
