import React from "react";
import { useQuery } from "react-query";
import { useNavigate, useSearchParams } from "react-router-dom";

import { useAuth } from "../../shared/hooks/auth-hook";

import type { ApiResponse, CompanyUpdateLog, PaginationState, UpdateLogFilters } from "./types";
import { filtersToSearchParams, parseUrlFilters } from "./utils";
import { fetchUpdateLogs } from "./api";
import { DEFAULT_PAGE_SIZE } from "./constants";

export const useUpdateLogs = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { token } = useAuth();

  const navigate = useNavigate();

  // Parse initial filters from URL
  const filters = parseUrlFilters(searchParams);
  const page = filters.page || 1;
  const pageSize = filters.pageSize || DEFAULT_PAGE_SIZE;

  const updateFilters = (newFilters: Partial<UpdateLogFilters>) => {
    const updatedFilters = {
      ...filters,
      ...newFilters,
    };

    // Reset to page 1 when filters change (except for page/pageSize changes)
    if (!("page" in newFilters) && !("pageSize" in newFilters)) {
      updatedFilters.page = 1;
    }

    // Remove empty filters
    Object.keys(updatedFilters).forEach((keyUntyped) => {
      const key = keyUntyped as keyof UpdateLogFilters;
      const field = updatedFilters[key];
      const isEmptyArray = Array.isArray(field) && field.length === 0;
      const isEmpty = !field;

      if (isEmpty || isEmptyArray) {
        delete updatedFilters[key];
      }
    });

    // Update URL
    const newSearchParams = filtersToSearchParams(updatedFilters);
    setSearchParams(newSearchParams);
  };

  const searchTermDebounced = useDebounce(filters.searchTerm, 350);

  const { data, ...queryRest } = useQuery<ApiResponse, Error>({
    queryKey: ["companyUpdateLogs", { ...filters, page, pageSize, searchTerm: searchTermDebounced }],
    queryFn: () => fetchUpdateLogs(token!, { ...filters, page, pageSize, searchTerm: searchTermDebounced }),
    keepPreviousData: true,
  });

  const handlePageChange = (newPage: number) => {
    updateFilters({ page: newPage });
  };

  const handlePageSizeChange = (newPageSize: number) => {
    updateFilters({ page: 1, pageSize: newPageSize });
  };

  const items: CompanyUpdateLog[] = data?.data ?? [];
  const pagination: PaginationState = data?.pagination ?? { page, pageSize, count: 0 };

  return {
    data: items,
    pagination,
    filters,
    updateFilters,
    handlePageChange,
    handlePageSizeChange,
    ...queryRest,
  };
};

export function useDebounce<T>(value: T, delay: number) {
  const [debouncedValue, setDebouncedValue] = React.useState(value);

  React.useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
}
