import { makeStyles } from "@material-ui/core/styles";
import backgroundImage from "../../assets/backgroundImage.png";

export const useStyles = makeStyles(() => ({
  searchPage: {
    position: "relative",
    paddingTop: "70px",
    "&::before": {
      content: "''",
      width: "100%",
      height: "65%",
      position: "absolute",
      top: 0,
      left: 0,
      zIndex: -1,
      backgroundImage: `url(${backgroundImage})`,
      backgroundSize: "cover",
    },
  },
  searchForm: {
    maxWidth: "950px",
    padding: "0 20px",
    margin: "0 auto",
  },
  filtersList: {
    backgroundColor: "#fff",
    marginTop: "50px",
    padding: "30px 0px 30px 18px",
    display: "flex",
    flexDirection: "column",
    boxShadow: "0 17px 40px 0 rgba(0,0,0,0.06), 0 2px 20px 0 rgba(0,0,0,0.1)",
  },
  filterRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: "32px",
  },
}));
