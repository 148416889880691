import React from "react";
import {
  Card,
  CardContent,
  Link,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";

import type { CompanyUpdateLog } from "../types";
import { UpdateLogTableSkeleton } from "./UpdateLogTableSkeleton";
import { EntityAvatar } from "./EntityAvatar";
import { formatDate } from "../utils";
import { UpdateLogActionsMenu } from "./UpdateLogActionsMenu";
import { UpdateLogStatus } from "./UpdateLogStatus";

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

interface UpdateLogListProps {
  logs: CompanyUpdateLog[];
  isLoading: boolean;
  error: unknown;
}

export const UpdateLogTable = ({ logs, isLoading, error }: UpdateLogListProps) => {
  if (error) {
    return (
      <Card>
        <CardContent>
          <Typography color="error">Failed to load update logs</Typography>
        </CardContent>
      </Card>
    );
  }

  return (
    <TableContainer component={Paper}>
      <Table size="small" aria-label="company updates table">
        <TableHead>
          <TableRow>
            <TableCell sx={{ minWidth: 250 }}>Company</TableCell>
            <TableCell sx={{ minWidth: 150 }}>Trigger Event</TableCell>
            <TableCell sx={{ minWidth: 150 }}>Data Source</TableCell>
            <TableCell sx={{ minWidth: 190 }}>Created At</TableCell>
            <TableCell sx={{ minWidth: 180 }}>Created By</TableCell>
            <TableCell sx={{ minWidth: 200 }}>Status</TableCell>
            <TableCell sx={{ width: 50 }}></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {isLoading ? (
            <UpdateLogTableSkeleton />
          ) : !logs?.length ? (
            <TableRow>
              <TableCell colSpan={7}>
                <Typography align="center">No updates found</Typography>
              </TableCell>
            </TableRow>
          ) : (
            logs.map((log) => (
              <StyledTableRow key={log.id}>
                <TableCell component="th" scope="row">
                  <Link href={`/companies/${log.company.id}`} target="_blank" rel="noopener">
                    <Stack
                      direction="row"
                      spacing={1}
                      alignItems="center"
                      sx={{
                        ...(log.company.isArchived && {
                          "& img": {
                            opacity: 0.5,
                          },
                        }),
                      }}
                    >
                      <EntityAvatar image={log.company.image} name={log.company.name} size={24} type="company" />
                      <span>
                        {log.company.name}
                        {log.company.isArchived && (
                          <Typography
                            component="span"
                            variant="caption"
                            sx={{
                              ml: 1,
                              color: "text.disabled",
                              backgroundColor: (theme) => theme.palette.action.hover,
                              px: 0.5,
                              py: 0.25,
                              borderRadius: 0.5,
                              textDecoration: "none",
                            }}
                          >
                            Archived
                          </Typography>
                        )}
                      </span>
                    </Stack>
                  </Link>
                </TableCell>
                <TableCell>{log.triggerEvent}</TableCell>
                <TableCell>{log.dataSource.name}</TableCell>
                <TableCell>{formatDate(log.createdAt)}</TableCell>
                <TableCell>
                  <Tooltip title={log.createdBy.email}>
                    <Stack direction="row" spacing={1} alignItems="center">
                      <EntityAvatar image={log.createdBy.image} name={log.createdBy.fullName} size={24} type="user" />
                      <span>{log.createdBy.fullName}</span>
                    </Stack>
                  </Tooltip>
                </TableCell>
                <TableCell>
                  <UpdateLogStatus log={log} />
                </TableCell>
                <TableCell sx={{ verticalAlign: "top", pt: 1.5 }}>
                  <UpdateLogActionsMenu log={log} />
                </TableCell>
              </StyledTableRow>
            ))
          )}
        </TableBody>
      </Table>
      {/*{logs.map((log) => (*/}
      {/*  <UpdateLogItem key={log.id} log={log} />*/}
      {/*))}*/}
    </TableContainer>
  );
};
