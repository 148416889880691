import { FC, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { SearchInput } from "../../components/search/searchInput";
import { MultiSelect } from "../../components/multiselect";
import { CheckboxSelect } from "../../components/checkbox";
import { ClearFilters } from "../../components/clearfilters";
import { ApplyButton } from "../../components/applyButton";
import { DatePickerComponent } from "../../components/datepicker";
import { MultiLevelFilter } from "../../components/multiLevelFilter.tsx/filter";
import { ICountry, IRangeItem, ISelectItem, IState, Level, TaxonomySegment } from "../../interfaces";
import { apiConfig } from "../../services/api/apiConfig";
import { Service } from "../../services/api/service";
import backgroundImage from "../../assets/backgroundImage.png";
import { useAuth } from "../../shared/hooks/auth-hook";
import { AsyncQueue } from "../../utils/asyncQueue";
import { AxiosResponse } from "axios";
import { convertTaxonomySegmentToLevel } from "../../utils/industries";

import { useStyles } from "./styles";

const asyncQueue = new AsyncQueue();
const countryStates: any = {};

export const SearchComponent: FC = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { token } = useAuth();

  const [searchInput, setSearchInput] = useState<string>("");

  const [countries, setCountries] = useState<ICountry[]>([]);
  const [selectedCountry, setSelectedCountry] = useState<ICountry[]>([]);

  const [states, setStates] = useState<IState[]>([]);
  const [selectedState, setSelectedState] = useState<IState[]>([]);

  const [primaryIndustriesLevel, setPrimaryIndustriesLevel] = useState<Level>({ selected_id: null, items: {} });
  const [selectedPrimaryIndustry, setSelectedPrimaryIndustry] = useState<(string | number)[]>([]);

  const [secondaryIndustriesLevel, setSecondaryIndustriesLevel] = useState<Level>({ selected_id: null, items: {} });
  const [selectedSecondaryIndustry, setSelectedSecondaryIndustry] = useState<(string | number)[]>([]);

  const [ownershipTypes, setOwnershipTypes] = useState<ISelectItem[]>([]);
  const [selectedOwnershipTypes, setSelectedOwnershipTypes] = useState<number[]>([]);

  const [employeeNumberRanges, setEmployeeNumberRanges] = useState<IRangeItem[]>([]);
  const [selectedEmployeeNumberRanges, setSelectedEmployeeNumberRanges] = useState<number[]>([]);

  const [revenueNumberRanges, setRevenueNumberRanges] = useState<IRangeItem[]>([]);
  const [selectedRevenueNumberRanges, setSelectedRevenueNumberRanges] = useState<number[]>([]);

  const [operationAreas, setOperationAreas] = useState<ISelectItem[]>([]);
  const [selectedOperationAreas, setSelectedOperationAreas] = useState<number[]>([]);

  const [storeNumberRanges, setStoreNumberRanges] = useState<IRangeItem[]>([]);
  const [selectedStoreNumberRanges, setSelectedStoreNumberRanges] = useState<number[]>([]);

  const [eiqGroups, setEiqGroups] = useState<ISelectItem[]>([]);
  const [selectedEiqGroups, setSelectedEiqGroups] = useState<number[]>([]);

  const [lastEditDate, setLastEditDate] = useState<(Date | null)[]>([null, null]);

  const [rtnMember, setRtnMember] = useState<boolean>(false);
  const [p2pMember, setP2pMember] = useState<boolean>(false);

  const [includeArchived, setIncludeArchived] = useState(false);

  const manageStates = async () => {
    let statesArray: IState[] = [];
    const allSelectedCountryStates = selectedCountry
      .map((item) => countryStates[item.id])
      .filter((item) => item && item.length > 0);
    for (let i = 0; i < allSelectedCountryStates.length; i++) {
      if (i > 0) statesArray = statesArray.concat({ id: 0, country_id: 0, name: "" });
      statesArray = statesArray.concat(allSelectedCountryStates[i]);
    }
    statesArray = statesArray.filter((item, key) => {
      if (key === 0 && item && item.id === 0) return false;
      return !!item;
    });
    setStates(statesArray);
  };

  const resetFilters = () => {
    setSelectedCountry([]);
    setSelectedState([]);
    setSelectedPrimaryIndustry([]);
    setSelectedSecondaryIndustry([]);
    setSelectedOwnershipTypes([]);
    setSelectedEmployeeNumberRanges([]);
    setSelectedRevenueNumberRanges([]);
    setSelectedStoreNumberRanges([]);
    setSelectedOperationAreas([]);
    setRtnMember(false);
    setP2pMember(false);
    setLastEditDate([null, null]);
    setSelectedEiqGroups([]);

    setIncludeArchived(false);
  };

  const search = () => {
    const filterData: any = {};
    if (selectedCountry.length) {
      filterData.selectedCountry = selectedCountry.map((el) => el.id);
    }
    if (selectedState.length) {
      filterData.selectedState = selectedState.map((el) => el.id);
    }
    if (selectedPrimaryIndustry.length) {
      filterData.selectedPrimaryIndustry = selectedPrimaryIndustry;
    }
    if (selectedSecondaryIndustry.length) {
      filterData.selectedSecondaryIndustry = selectedSecondaryIndustry;
    }
    if (selectedOwnershipTypes.length) {
      filterData.selectedOwnershipTypes = selectedOwnershipTypes;
    }
    if (selectedEmployeeNumberRanges.length) {
      filterData.selectedEmployeeNumberRanges = selectedEmployeeNumberRanges;
    }
    if (selectedRevenueNumberRanges.length) {
      filterData.selectedRevenueNumberRanges = selectedRevenueNumberRanges;
    }
    if (selectedStoreNumberRanges.length) {
      filterData.selectedStoreNumberRanges = selectedStoreNumberRanges;
    }
    if (selectedOperationAreas.length) {
      filterData.selectedOperationAreas = selectedOperationAreas;
    }
    if (selectedEiqGroups.length) {
      filterData.selectedEiqGroups = selectedEiqGroups;
    }
    if (lastEditDate[0] && lastEditDate[1]) {
      filterData.modified_at = lastEditDate;
    }
    if (rtnMember) {
      filterData.rtn_member = rtnMember ? 1 : 0;
    }
    if (p2pMember) {
      filterData.p2pi_member = p2pMember ? 1 : 0;
    }
    if (searchInput) {
      filterData.searchInput = searchInput;
    }

    const status = includeArchived ? "active,archived" : "active";
    filterData.status = status;

    navigate(`/result?json=${JSON.stringify(filterData)}`, {
      state: {
        history: `/search`,
        primaryIndustriesLevel: primaryIndustriesLevel,
        secondaryIndustriesLevel: secondaryIndustriesLevel,
      },
    });
  };

  const handlePrimaryIndustrySelect = (selected: (string | number)[] | (string | number), level: Level) => {
    setSelectedPrimaryIndustry(selected as (string | number)[]);
    setPrimaryIndustriesLevel(level);
  };

  const handleSecondaryIndustrySelect = (selected: (string | number)[] | (string | number), level: Level) => {
    setSelectedSecondaryIndustry(selected as (string | number)[]);
    setSecondaryIndustriesLevel(level);
  };

  useEffect(() => {
    Service.get(apiConfig.countries, token as string).then((response) => {
      setCountries(response.data);
      setOperationAreas(response.data);
    });

    Service.get(apiConfig.ownershipTypes, token as string).then((response) => setOwnershipTypes(response.data));

    Service.get(apiConfig.employeeNumberRanges, token as string).then((response) =>
      setEmployeeNumberRanges(response.data)
    );

    Service.get(apiConfig.eiqGroups, token as string).then((response) => setEiqGroups(response.data.data));

    Service.get(apiConfig.revenueNumberRanges, token as string).then((response) =>
      setRevenueNumberRanges(response.data)
    );

    Service.get(apiConfig.companiesPrimaryIndustriesAll, token as string)
      .then((response: AxiosResponse<TaxonomySegment[]>) =>
        setPrimaryIndustriesLevel(convertTaxonomySegmentToLevel(response.data))
      )
      .catch(console.error);

    Service.get(apiConfig.companiesSecondaryIndustriesAll, token as string)
      .then((response: AxiosResponse<TaxonomySegment[]>) =>
        setSecondaryIndustriesLevel(convertTaxonomySegmentToLevel(response.data))
      )
      .catch(console.error);

    Service.get(apiConfig.storeNumberRanges, token as string).then((response) => setStoreNumberRanges(response.data));
  }, []);

  useEffect(() => {
    const countryIds: number[] = selectedCountry.map((item) => item.id);
    const newSelectedStates = selectedState.filter((item) => countryIds.includes(item.country_id));
    setSelectedState(newSelectedStates);
    for (let i = 0; i < selectedCountry.length; i++) {
      const country = selectedCountry[i];
      const queueId = "search-page-country-" + country.id;
      asyncQueue.insert(
        queueId,
        async () => {
          const response = await Service.get(`${apiConfig.countries}/${country.id}/states`, token as string);
          return response.data;
        },
        (error: any, response: any) => {
          if (!error) {
            countryStates[country.id] = response;
            manageStates();
          }
        }
      );
    }
  }, [selectedCountry]);

  return (
    <div className={classes.searchPage}>
      <div className={classes.searchForm}>
        <SearchInput value={searchInput} setValue={(value) => setSearchInput(value)} search={search} />
        <div className={classes.filtersList}>
          <div className={classes.filterRow}>
            <MultiLevelFilter
              title="Primary Industry"
              data={primaryIndustriesLevel}
              selected={selectedPrimaryIndustry}
              setSelected={handlePrimaryIndustrySelect}
            />

            <MultiLevelFilter
              title="Secondary Industry"
              data={secondaryIndustriesLevel}
              selected={selectedSecondaryIndustry}
              setSelected={handleSecondaryIndustrySelect}
            />

            <MultiSelect
              title="HQ Country"
              items={countries}
              stringify={(item: ICountry) => item.name}
              value={(item: ICountry) => item.id}
              selected={selectedCountry.map((item) => item.id)}
              setSelected={(values: string[] | number[]) => {
                const values2: number[] = values.map((item) => parseInt(item + ""));

                const newSelectedCountries: ICountry[] = countries.filter(
                  (item) => item.id && values2.includes(item.id)
                );
                setSelectedCountry(newSelectedCountries);
              }}
            />

            <MultiSelect
              title="HQ State/Province"
              items={states}
              stringify={(item: IState) => item.name}
              value={(item: IState) => item.id}
              selected={selectedState.map((item) => item.id)}
              setSelected={(values: string[] | number[]) => {
                const values2: number[] = values.map((item) => parseInt(item + ""));
                const selectedStates: IState[] = states.filter((item) => item.id && values2.includes(item.id));
                setSelectedState(selectedStates);
              }}
            />
          </div>
          <div className={classes.filterRow}>
            <MultiSelect
              title="Ownership Type"
              items={ownershipTypes}
              stringify={(item: ISelectItem) => item.name}
              value={(item: ISelectItem) => item.id}
              selected={selectedOwnershipTypes}
              setSelected={(values: string[] | number[]) => setSelectedOwnershipTypes(values as number[])}
            />

            <MultiSelect
              title="# of Employee Range"
              items={employeeNumberRanges}
              stringify={(item: IRangeItem) => item.name}
              value={(item: IRangeItem) => item.id}
              selected={selectedEmployeeNumberRanges}
              setSelected={(values: string[] | number[]) => setSelectedEmployeeNumberRanges(values as number[])}
            />

            <MultiSelect
              title="Revenue Range"
              items={revenueNumberRanges}
              stringify={(item: IRangeItem) => item.name}
              value={(item: IRangeItem) => item.id}
              selected={selectedRevenueNumberRanges}
              setSelected={(values: string[] | number[]) => setSelectedRevenueNumberRanges(values as number[])}
            />

            <MultiSelect
              title="Area of Operations"
              items={operationAreas}
              stringify={(item: ISelectItem) => item.name}
              value={(item: ISelectItem) => item.id}
              selected={selectedOperationAreas}
              setSelected={(values: string[] | number[]) => setSelectedOperationAreas(values as number[])}
            />
          </div>
          <div className={classes.filterRow} style={{ justifyContent: "center" }}>
            <div style={{ width: "200px", marginRight: "20px" }}>
              <MultiSelect
                title="EIQ Groups"
                items={eiqGroups}
                stringify={(item: ISelectItem) => item.name}
                value={(item: ISelectItem) => item.id}
                selected={selectedEiqGroups}
                setSelected={(values: string[] | number[]) => setSelectedEiqGroups(values as number[])}
              />
            </div>
            <div style={{ width: "200px", marginRight: "20px" }}>
              <MultiSelect
                title="Store Number Range"
                items={storeNumberRanges}
                stringify={(item: IRangeItem) => item.name}
                value={(item: IRangeItem) => item.id}
                selected={selectedStoreNumberRanges}
                setSelected={(values: string[] | number[]) => setSelectedStoreNumberRanges(values as number[])}
              />
            </div>
            <DatePickerComponent
              title="Last Edit Date"
              dates={lastEditDate}
              setDateValue={(value: Date[]) => setLastEditDate(value)}
            />
          </div>
          <div className={classes.filterRow} style={{ justifyContent: "center" }}>
            <CheckboxSelect
              checkboxLabel="RTN Member"
              checked={rtnMember}
              setChecked={(checked: boolean) => setRtnMember(checked)}
              labelStyling={{
                marginLeft: -3,
                color: "#4d748d",
                fontFamily: "Montserrat, sans-serif",
                fontSize: 12,
              }}
              marginLeft="32px"
            />
            <CheckboxSelect
              checkboxLabel="P2PI Member"
              checked={p2pMember}
              setChecked={(checked: boolean) => setP2pMember(checked)}
              labelStyling={{
                marginLeft: -3,
                color: "#4d748d",
                fontFamily: "Montserrat, sans-serif",
                fontSize: 12,
              }}
              marginLeft="32px"
            />
          </div>
          <div
            style={{
              marginTop: "30px",
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              marginRight: "32px",
            }}
          >
            <div style={{ marginRight: "auto" }}>
              <CheckboxSelect
                checkboxLabel="Include archived profiles"
                checked={includeArchived}
                setChecked={(checked: boolean) => setIncludeArchived(checked)}
                labelStyling={{
                  marginLeft: -3,
                  color: "#4d748d",
                  fontFamily: "Montserrat, sans-serif",
                  fontSize: 12,
                }}
              />
            </div>
            <ClearFilters text="Clear all filters" clear={resetFilters} />
            <span style={{ marginLeft: 10 }}></span>
            <ApplyButton text="Search" apply={search} />
          </div>
        </div>
      </div>
    </div>
  );
};
